import React from 'react';
import { Link } from 'gatsby';
import { Seo } from '../../../modules/common/components/seo';
import { DashboardLayout } from '../../../modules/layout/components/dashboard-layout';
import { StaticImage } from 'gatsby-plugin-image';
import { SectionHeader } from '../../../modules/common/components/section-header';

import '../../generic-page.scss';
import '../index.scss';
import './guide.scss';
import { WWCharacter } from '../../../modules/ww/common/components/ww-character';

const WWGuidesFreeChars: React.FC = () => {
  return (
    <DashboardLayout className={'generic-page ww-guide'} game="ww">
      <ul className="breadcrumb">
        <li>
          <Link to="/wuthering-waves/">Wuthering Waves</Link>
        </li>
        <li className="divider">/</li>
        <li>
          <Link to="/wuthering-waves/guides">Guides</Link>
        </li>
        <li className="divider">/</li>
        <li>Free characters</li>
      </ul>
      <div className="page-header">
        <div className="page-icon">
          <StaticImage
            src="../../../images/ww/categories/category_freechars.jpg"
            alt="Guides"
          />
        </div>
        <div className="page-details">
          <h1>Wuthering Waves Free characters</h1>
          <h2>
            List of characters you can obtain for free in Wuthering Waves and
            how strong they are!
          </h2>
          <p>
            Last updated: <strong>22/05/2024</strong>
          </p>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297235"></div>
      <div className="page-content">
        <SectionHeader title="Information" />
        <p>
          As you progress through the story in Wuthering Waves you will obtain
          quite a lot of free 4★ characters. This guide will list all the free
          characters and also let you know when you will be able to obtain them
          - so you can easier plan your early game progression team!
        </p>
        <p>
          Also keep in mind that a lot of the 4★ characters are actually really
          strong and can be the backbone of your team for quite a long time.
        </p>
        <SectionHeader title="Free characters" />
        <div className="reroll-char-info reroll first">
          <div className="top Spectro">
            <p className="name">Rover [Hybrid]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="rover-spectro"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Rover is the main character in Wuthering Waves, starting as a
                Spectro-based Hybrid character who is a jack-of-all-trades,
                splitting their focus between dealing damage and controlling the
                battlefield. In the future, you can expect other elemental
                variants of the Rover that use different Weapons to be released,
                similar to other games in the genre, but for now, you will be
                stuck with the Spectro variant, so let's dig into what they
                offer.
              </p>
              <p>
                Rover tries to do many things at once and while they can perform
                adequately well in all of them, they aren’t top of their role at
                anything - which early on isn't that big of a downside but as
                you obtain more characters, you will most likely want to switch
                to them due their more specialized roles. Rover's damage is some
                of the highest of any hybrid character without considering
                coordinated attacks, partially thanks to their high base stats,
                and the fact you get all their sequences for free (many of which
                are damage boosts). In the CBT2 they were often played with
                damage-focused builds capitalizing on their fast and precise
                attack frames that allow for effective parrying against endgame
                bosses.
              </p>
              <p>
                Overall, Rover's a great free character who is easy to learn and
                can be played in ways with a variety of builds, allowing them to
                'fill in' for whatever you're missing - especially considering
                that for the endgame, you need 3 teams, in 1.0 you’ll likely
                always find a use for them.{' '}
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Boasts a good combination of damage and utility,</li>
                <li>Can quickly fill their Forte Gauge,</li>
                <li>All Sequences are available for free,</li>
                <li>
                  Fast and precise attack frames that make parrying easier,
                </li>
                <li>
                  High utility outro, opening enemies for an easy combo or a
                  quick escape.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  While early on, they will feel really strong due to the free
                  Sequences, the rest of the cast will catch up once you start
                  getting theirs,
                </li>
                <li>
                  While the damage output is really good, it still lags behind
                  the dedicated main DPS, and at the same time, their Outro is a
                  CC effect, not a buff, making it hard to find a place for them
                  in most endgame best-in-slot compositions.
                </li>
              </ul>
            </div>
            <div className="ratings smaller">
              <p>
                Source: <strong>Starter character</strong>
              </p>
              <p>
                Worth investing? <strong>Yes!</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Aero">
            <p className="name">Yangyang [Support]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="yangyang"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Yangyang is a Support/Hybrid character who doesn't provide a
                Deepen buff with her Outro but instead recovers Energy for the
                next character. In most games, characters that can recover
                energy for their allies are always strong, but in Wuthering
                Waves it's difficult to assess the power of this before seeing
                her used by more players on release - it could be quite strong
                or quite weak. Yangyang is one of the few characters in the game
                with an Outro that can be used offensively but doesn’t lose its
                effect when the receiving character switches out allowing her to
                be built as a Hybrid character or Support. To play her as a
                Support she can make use of a weapon that triggers a heal after
                unleashing her ultimate which allows her to make use of the
                powerful 5P Rejuvenating Glow set which on release doesn’t have
                many viable users (while being absurdly powerful).
              </p>
              <p>
                Yang Yang has a few downsides though. Her basic attack rotation
                is a little unforgiving and if you miss the last part, you will
                lose a big portion of her Forte Gauge, forcing you to restart
                the basic attack combo from scratch. Her personal damage is also
                on the lower side, further pushing her more into a Supporting
                role than a Hybrid. Her required on-field time is a little
                awkward as It's longer than other Supports but shorter than a
                lot of Hybrids, which puts her in a weird spot. Overall Yangyang
                is an interesting character that potentially can be
                game-changing depending on how important her Energy Recovery
                will be in the endgame, but until we see more from her it’s
                difficult to gauge just how powerful she’ll be or if she’ll be
                present in any top-tier teams and if that’ll be in the Hybrid or
                Support role.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Requires low field time to execute Concerto combo, with the
                  ability to get in and out fast (for a Hybrid character),
                </li>
                <li>Outro that recovers energy,</li>
                <li>Can gather enemies both with her skill and ultimate,</li>
                <li>
                  Can use a healing Weapon to effectively turn her into a
                  support which synergizes with a lot of the rest of her kit,
                </li>
                <li>Gains great utility with Sequences,</li>
                <li>She's free!</li>
              </ul>
              <ul className="cons">
                <li>No Deepen buff on Outro skill,</li>
                <li>
                  Unforgiving basic combo which if missed costs more field time,
                </li>
                <li>Mediocre damage output,</li>
                <li>In a weird spot between a Hybrid and Support</li>
              </ul>
            </div>
            <div className="ratings smaller">
              <p>
                Source: <strong>Prologue</strong>
              </p>
              <p>
                Worth investing? <strong>Yes!</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Fusion">
            <p className="name">Chixia [Main DPS]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="chixia"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Chixia is a Main DPS that offers a completely ranged playstyle,
                perfect for those who want to rain destruction upon their
                enemies from afar while kiting them and dodging their attacks
                with ease. She's also incredibly easy to understand and play as
                her kit revolves around building and spending Bullets via her
                Basic Attack and Skill. Her damage output is decent for a
                4-star, and one that you obtain for free in the tutorial on top
                of that, so every additional copy of her you will obtain from
                pulls with further amplify her performance.
              </p>
              <p>
                But... There's always a but. Being easy to play and master,
                comes at a cost and the cost is pretty high for Chixia. First of
                all, she requires the longest on-field time among all Main DPS -
                fortunately, this can be fixed with her Sequences but this
                increases her reliance on her Ultimate. Also, while she can move
                when she's channeling during the DAKA DAKA! Mode (her enhanced
                Resonance skill), she's vulnerable to interruptions if your
                position is off and the enemy starts a big attack - especially
                against those enemies that have fast animations. And if you stop
                channeling to avoid the attack, it will come with a big damage
                loss.
              </p>
              <p>
                Overall, Chixia is a decent character who will help you progress
                through the early game, but you will most likely bench her once
                you pull characters that perform better.{' '}
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Completely ranged playstyle,</li>
                <li>Easy to understand and play,</li>
                <li>
                  Deals a tremendous amount of resonance damage with her
                  channeled skill combos,
                </li>
                <li>Can move while channeling her resonance skill,</li>
                <li>
                  Has a strong dash attached to her resonance skill that can be
                  used for quick repositioning,
                </li>
                <li>You get her for free!</li>
              </ul>
              <ul className="cons">
                <li>
                  Almost completely single target outside a few burst skills,
                </li>
                <li>Cannot parry as a ranged character,</li>
                <li>Expensive ultimate,</li>
                <li>Takes up the most on-field time among all Main DPS,</li>
                <li>
                  Vulnerable to interruption by fast enemies or large attacks
                  while channeling resonance skill if positioned poorly.
                </li>
              </ul>
            </div>
            <div className="ratings smaller">
              <p>
                Source: <strong>Prologue</strong>
              </p>
              <p>
                Worth investing? <strong>Not really.</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Glacio">
            <p className="name">Baizhi [Support]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="baizhi"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Baizhi is a Support who can be summed up as 'Verina at home'.
                Jokes aside, Baizhi is a very versatile Support character who
                can be slotted into any team due to her healing and buffing
                capabilities. She has an incredible Outro skill that is only
                inferior to Verina's and that provides All-Type DMG Deepen to
                the next ally, but compared to other 'next-type' Outros, the
                buff isn't lost when you switch as it retriggers on heal. Her
                Sequences also give her a teamwide Cheat Death mechanic (at S5)
                and a Glacio DMG% buff that helps characters from that element
                (at S6).
              </p>
              <p>
                Sadly, the 'Verina at home' line wasn't a joke and Baizhi is
                worse than her in almost every way. She requires more on-field
                time, her healing is worse, her buffs are worse and she's a bit
                clunky to play - for example, while she's a ranged character,
                she still has to remain in melee range to gain resonance energy
                from her Resonance Skill (she can still heal and get concerto
                from it though). Her pet who actually is the one attacking also
                had a bit of delay in the CBT2, causing gameplay to sometimes
                feel sluggish which is especially noticeable when comparing her
                to Verina. We are unsure if this will be fixed on release.
              </p>
              <p>
                Overall, despite the fact that Baizhi is inferior to Verina
                she's still the second-best Support available to us on release,
                and since she's being given for free, you will use her in your
                second or third team in Tower of Adversity even after you obtain
                Verina.
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>
                  Incredible supportive Outro, granting 15% DMG Deepen which is
                  not lost when switching,
                </li>
                <li>
                  Can effortlessly enable the 5P Healer set bonus without
                  sacrificing her weapon choice,
                </li>
                <li>
                  Completely universal - every single team can use Baizhi,
                </li>
                <li>Has decent team-wide healing,</li>
                <li>
                  Can grant the team a cheat-death after unlocking S5 in the
                  form of a full heal to the character that would have suffered
                  fatal damage,
                </li>
                <li>Has a pet? Dinosaur? Thing..?</li>
              </ul>
              <ul className="cons">
                <li>
                  Verina at home - is worse in almost every way requiring more
                  field time, and effort to play, and is still more clunky after
                  all that,
                </li>
              </ul>
            </div>
            <div className="ratings smaller">
              <p>
                Source: <strong>First Convene</strong>
              </p>
              <p>
                Worth investing? <strong>Yes!</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Electro">
            <p className="name">Yuanwu [Hybrid]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="yuanwu"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>Coming soon!</p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>Coming soon!</li>
              </ul>
              <ul className="cons">
                <li>Coming soon!</li>
              </ul>
            </div>
            <div className="ratings smaller">
              <p>
                Source: <strong>Event</strong>
              </p>
              <p>
                Worth investing? <strong>Coming Soon!</strong>
              </p>
            </div>
          </div>
        </div>
        <div className="reroll-char-info reroll">
          <div className="top Glacio">
            <p className="name">Sanhua [Hybrid]</p>
          </div>
          <div className="middle">
            <div className="character">
              <div className="employee-container for-nikke">
                <WWCharacter
                  mode="icon"
                  slug="sanhua"
                  enablePopover
                  showLabel
                />
              </div>
            </div>
            <div className="pros-cons">
              <p>
                Sanhua is a Hybrid character who has one of the fastest possible
                Concerto rotations in the game, allowing her to execute quickly,
                deal great burst damage, and switch out granting her Concerto
                outro buff to those who need it. Her Ultimate is tied for the
                lowest cost in the game (100), and she generates a decent amount
                of energy herself, meaning she can focus on a more offensive
                build instead of Energy Regen, further adding to her burst
                potential.{' '}
              </p>
              <p>
                Burst damage is only one of her playstyles and she can choose to
                incorporate some Basic Attacks into her rotation if desired,
                altering her from Burst-oriented playstyle into sustained
                on-field damage one, to take advantage of some of her Basic
                Attack focused kit. Still, no matter the playstyle, you always
                have to keep in mind her Forte Gauge mini-game that forces you
                to time her heavy Basic Attack detonate at the right moment.
                Missing this will decrease the amount of damage and energy
                Sanhua recovers and negatively affect her rotation.{' '}
              </p>
              <p>
                Overall, Sanhua is a great character whom everyone will obtain
                for free from the login calendar on the 5th day. So don't let
                her rot on your account and try her out - she won't disappoint
                you!
              </p>
              <p>
                <strong>Pros & Cons</strong>
              </p>
              <ul className="pros">
                <li>One of the fastest concerto rotations in the game,</li>
                <li>
                  Fantastic burst damage when using her ultimate, intro, and
                  skill in combination with an Ice Burst,
                </li>
                <li>
                  Gains an absurd amount of Concerto when detonating the ice
                  spawned from her abilities,
                </li>
                <li>
                  Low energy cost of 100 means she can consistently pull off her
                  super-fast burst combo,
                </li>
                <li>
                  She’s free so if you get another copy you’ll start getting
                  Sequences, making her stronger.
                </li>
              </ul>
              <ul className="cons">
                <li>
                  Need to time the Forte's mini-game properly in the combos,
                </li>
                <li>
                  The burst-specific rotation doesn't use any Basic Attacks,
                  wasting parts of her kit even if it’s optimal.
                </li>
              </ul>
            </div>
            <div className="ratings smaller">
              <p>
                Source: <strong>Event</strong>
              </p>
              <p>
                Worth investing? <strong>Yes!</strong>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div className="fuse-ad-placeholder" data-fuse="22844297238"></div>
    </DashboardLayout>
  );
};

export default WWGuidesFreeChars;

export const Head: React.FC = () => (
  <Seo
    title="Free characters | Wuthering Waves | Prydwen Institute"
    description="List of characters you can obtain for free in Wuthering Waves and how strong they are!"
    game="ww"
  />
);
